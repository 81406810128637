import {createRouter, createWebHashHistory} from 'vue-router';
import LoginService from './service/LoginService'
import api from "@/main.js";

const routes = [
    {
        path: '/',
        redirect: '/acessoNegado'
    },
    {
        path: '/:pathMatch(.*)*',
        name: 'notfound',
        component: () => import('./pages/NotFound.vue')
    },
    {
        path: '/acessoNegado',
        name: 'acessoNegado',
        component: () => import('./pages/AcessoNegado.vue')
    },
    {
        path: '/viewescrito',
        name: 'viewescrito',
        component: () => import('./pages/PageViewEscrito.vue')
    },
    {
        path: '/viewescrito1',
        name: 'viewescrito1',
        component: () => import('./pages/PageViewEscrito1.vue')
    },
];

const router = createRouter({
    history: createWebHashHistory(),
    routes,
    scrollBehavior () {
        return { left: 0, top: 0 };
    }
});


router.beforeEach((to) => {
    var loginService = new LoginService();
    if(to.path == '/viewescrito' || to.path == '/viewescrito1') {
        //TODO: Adicionado para manter compatibilidade com o DEC; Remover após ajustes na chamada do DEC
        if (to.query && to.query.sessionId) {
            loginService.clearUserToken();
            document.cookie = "__knowledge_user_token="+to.query.sessionId+"; SameSite=Strict; domain=tce.am.gov.br; secure=true; MaxAge=25000";
            document.cookie = "__knowledge_user_token="+to.query.sessionId+"; SameSite=Strict; domain=localhost; secure=true; MaxAge=25000";
            // api.defaults.headers["Authorization"] = "Bearer " + to.query.sessionId;
        }
        //TODO: Adicionado para manter compatibilidade com o DEC; Remover após ajustes na chamada do DEC
        api.defaults.headers["Authorization"] = "Bearer " + loginService.getUserToken();
    }
});

export default router;