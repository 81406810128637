import EventBus from '@/event-bus';

export const usuario = '__knowledge_user_visualizador'
export const usuario_token = '__knowledge_user_token_visualizador'
export const usuario_setores = '__knowledge_setores_visualizador'
export const usuario_setor_selecionado = '__knowledge_setor_selecionado_visualizador'
export const usuario_nome_setor_selecionado = '__knowledge_nome_setor_selecionado_visualizador'
export const usuario_permissoes_setor_selecionado = '__knowledge_permissoes_setor_selecionado_visualizador'
export const usuario_id_logado = '__knowledge_id_logado_visualizador'
export const spede_api_version = '__knowledge_spede_api_visualizador'
//export const SPEDE_VISUALIZADOR_URL = 'http://localhost:8081'
export const SPEDE_VISUALIZADOR_URL = 'http://visualizador-homologacao.tce.am.gov.br'

export function showSucess(e) {
    let msg = "Operação realizada com sucesso!";
    if(e && typeof e === 'string') {
        msg = e;
    }
    EventBus.$emit("showSucess", msg);
 }

 export function showError(e) {
    let msg = e;
    if(e && e.response && e.response.data) {
        msg = e.response.data;
    } else if(typeof e === 'string') {
        msg = e;
    } else if(e && e.message) {
        msg = e.message;
    }
    EventBus.$emit("showError", msg);
 }

export default { usuario, usuario_token, usuario_setores, usuario_setor_selecionado, usuario_permissoes_setor_selecionado, SPEDE_VISUALIZADOR_URL, usuario_id_logado}