export default [
  {name: 'Adicionar Documento', code: "anexarDocumento", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_DOCUMENTOS", restrito: false },
  {name: 'Adicionar Pendência', code: "adicionarPendencia", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_DOCUMENTOS", restrito: false },
  {name: 'Alterar', code: "alterarProcesso", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: false },
  {name: 'Alterar', code: "alterarDocumento", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_DOCUMENTOS", restrito: false },
  {name: 'Alterar', code: "alterarDocumento", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_RASCUNHOS", restrito: false },
  {name: 'Alterar', code: "alterarManifestacao", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_MANIFESTACOES", restrito: true },
  {name: 'Alterar Fase', code: "alterarFase", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: false },
  {name: 'Alterar Fase', code: "alterarFase", tela: "CAIXA_TRABALHO_UNIDADE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Alterar Marcadores', code: "alterarMarcadores", tela: "CAIXA_ENTRADA_PESSOAL", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Alterar Marcadores', code: "alterarMarcadores", tela: "CAIXA_ENTRADA_UNIDADE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Alterar Marcadores', code: "alterarMarcadores", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Alterar Marcadores', code: "alterarMarcadores", tela: "CAIXA_TRABALHO_UNIDADE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Alterar Marcadores', code: "alterarMarcadores", tela: "CAIXA_SAIDA_UNIDADE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Alterar Marcadores', code: "alterarMarcadores", tela: "CAIXA_SAIDA_PESSOAL", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Alterar Metadados do Processo', code: "alterarMetadadoProcesso", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: false },
  {name: 'Alterar Metadados do Processo', code: "alterarMetadadoProcesso", tela: "CAIXA_TRABALHO_UNIDADE", subtela: "SUBTELA_PROCESSOS", restrito: false },
  {name: 'Alterar Metadados do Processo', code: "alterarMetadadoProcesso", tela: "TELA_CONSULTA_PROCESSO", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Anexar Documento', code: "anexarDocumento", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_DOCUMENTOS", restrito: false },
  {name: 'Anexar Documento', code: "anexarDocumento", tela: "TELA_CONSULTA_DOCUMENTO", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Apensar Processo', code: "apensarProcesso", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: false },
  {name: 'Arquivar Manifestação', code: "arquivarManifestacao", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_MANIFESTACOES", restrito: true },
  {name: 'Arquivar Documento', code: "arquivarDocumento", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_DOCUMENTOS", restrito: false },
  {name: 'Arquivar Processo', code: "arquivarProcesso", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: true },
  {name: 'Assinar Documento', code: "assinarDocumento", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_RASCUNHOS", restrito: false },
  {name: 'Atender Solicitação de Ciência', code: "atenderSolicitacaoCiencia", tela: "CAIXA_SOLICITACAO_RECEBIDA_PESSOAL", subtela: "SUBTELA_SOL_CIENCIA", restrito: false },
  {name: 'Atender Solicitação de Visto', code: "atenderSolicitacaoVisto", tela: "CAIXA_SOLICITACAO_RECEBIDA_PESSOAL", subtela: "SUBTELA_SOL_VISTO", restrito: false },
  {name: 'Atender Solicitação de Assinatura', code: "atenderSolicitacaoAssinatura", tela: "CAIXA_SOLICITACAO_RECEBIDA_PESSOAL", subtela: "SUBTELA_SOL_ASSINATURA", restrito: false },
  {name: 'Autuar Documento', code: "autuarDocumento", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_DOCUMENTOS", restrito: true },
  {name: 'Autuar Manifestação', code: "autuarManifestacao", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_MANIFESTACOES", restrito: true },
  {name: 'Cancelar Envio', code: "cancelarEnvio", tela: "CAIXA_SAIDA_PESSOAL", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Cancelar Envio', code: "cancelarEnvio", tela: "CAIXA_SAIDA_UNIDADE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Cancelar Número de Documento', code: "cancelarNumeroDocumento", tela: "TELA_CONSULTA_NUMERO_DOC", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Cancelar Solicitação', code: "cancelarSolicitacao", tela: "CAIXA_SOLICITACAO_ENVIADA_PESSOAL", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Cancelar Solicitação', code: "cancelarSolicitacao", tela: "CAIXA_SOLICITACAO_ENVIADA_UNIDADE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Cancelar Protocolo', code: "cancelarProtocolo", tela: "CAIXA_PROTOCOLO_PORTAL_VERIFICAR_QUALIDADE", subtela: "SUBTELA_TODOS", restrito: false },
  //{name: 'Cancelar Protocolo', code: "cancelarProtocolo", tela: "CAIXA_PROTOCOLO_PORTAL_VERIFICAR_QUALIDADE", subtela: "SUBTELA_TODOS", restrito: false },
  //{name: 'Cancelar Protocolo', code: "cancelarProtocolo", tela: "CAIXA_PROTOCOLO_PORTAL_ANALISE", subtela: "SUBTELA_TODOS", restrito: false },
  //{name: 'Cancelar Protocolo', code: "cancelarProtocolo", tela: "CAIXA_PROTOCOLO_VALIDACAO", subtela: "SUBTELA_TODOS", restrito: false },
  //{name: 'Cancelar Protocolo', code: "cancelarProtocolo", tela: "CAIXA_PROTOCOLO_PORTAL_PENDENTE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Comentar', code: "comentar", tela: "CAIXA_SAIDA_UNIDADE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Comentar', code: "comentar", tela: "TELA_GESTAO_PROCESSUAL", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Comentar', code: "comentar", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Comentar', code: "comentar", tela: "CAIXA_TRABALHO_UNIDADE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Comentar', code: "comentar", tela: "TELA_CONSULTA_PROCESSO", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Comentar', code: "comentar", tela: "TELA_CONSULTA_MANIFESTACAO", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Comentar', code: "comentar", tela: "TELA_CONSULTA_DOCUMENTO", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Comentar', code: "comentar", tela: "CAIXA_ENTRADA_PESSOAL", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Comentar', code: "comentar", tela: "CAIXA_ENTRADA_UNIDADE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Comentar', code: "comentar", tela: "CAIXA_SAIDA_PESSOAL", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Confirmar Recebimento', code: "confirmarRecebimento", tela: "CAIXA_ENTRADA_PESSOAL", subtela: "SUBTELA_TODOS" , restrito: false},
  {name: 'Confirmar Recebimento', code: "confirmarRecebimento", tela: "CAIXA_ENTRADA_UNIDADE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Definir Localização Física', code: "definirLocalizacaoFisica", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: true },
  //{name: 'Definir Localização Física', code: "definirLocalizacaoFisica", tela: "TELA_CONSULTA_PROCESSO", subtela: "SUBTELA_ARQUIVADO", restrito: false },
  {name: 'Desapensar Processo', code: "desapensarProcesso", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: false },
  {name: 'Desarquivar Documento', code: "reabrirDocumento", tela: "TELA_CONSULTA_DOCUMENTO", subtela: "SUBTELA_ARQUIVADO", restrito: false },
  {name: 'Desarquivar Processo', code: "reabrirProcesso", tela: "TELA_CONSULTA_PROCESSO", subtela: "SUBTELA_ARQUIVADO", restrito: true },
  {name: 'Desarquivar Manifestação', code: "reabrirManifestacao", tela: "TELA_CONSULTA_MANIFESTACAO", subtela: "SUBTELA_ARQUIVADO", restrito: true },
  //{name: 'Devolver à Fábrica', code: "devolveFabricaProt", tela: "CAIXA_PROTOCOLO_ANALISE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Distribuir Processo - Relator', code: "distribuirProcesso", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: true },
  {name: 'Distribuir Processo - Relator', code: "distribuirProcesso", tela: "TELA_CONSULTA_PROCESSO", subtela: "SUBTELA_TODOS", restrito: true },
  {name: 'Distribuir Processo - Procuradoria', code: "distribuirProcessoProcuradoria", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: true },
  {name: 'Distribuir Processo - Procuradoria', code: "distribuirProcessoProcuradoria", tela: "TELA_CONSULTA_PROCESSO", subtela: "SUBTELA_TODOS", restrito: true },
  {name: 'Enviar documentos por e-mail', code: "enviarDocumentosPorEmail", tela: "CAIXA_ENTRADA_PESSOAL", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Enviar documentos por e-mail', code: "enviarDocumentosPorEmail", tela: "CAIXA_ENTRADA_UNIDADE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Enviar documentos por e-mail', code: "enviarDocumentosPorEmail", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Enviar documentos por e-mail', code: "enviarDocumentosPorEmail", tela: "CAIXA_TRABALHO_UNIDADE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Enviar para Caixa de Trabalho Pessoal', code: "processarProtocoloPortal", tela: "CAIXA_PROTOCOLO_PORTAL_ANALISE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Enviar para Caixa de Trabalho Pessoal', code: "processarManifestacao", tela: "CAIXA_MANIFESTACAO_NOVA", subtela: "SUBTELA_TODOS", restrito: false },
  //{name: 'Enviar para Caixa de Trabalho Pessoal', code: "processarProtocolo", tela: "CAIXA_PROTOCOLO_ANALISE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Enviar (Tramitar)', code: "tramitar", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Enviar (Tramitar)', code: "tramitar", tela: "CAIXA_TRABALHO_UNIDADE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Enviar para Julgamento', code: "enviarJulgamento", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: true },
  {name: 'Excluir Documento', code: "excluirDocumento", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_RASCUNHOS", restrito: false },
  {name: 'Finalizar Manifestação', code: "finalizarManifestacao", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_MANIFESTACOES", restrito: true },
  {name: 'Gerar Voto', code: "gerarVoto", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: true },
  {name: 'Incluir no Estoque Inativo', code: "sobrestarEscrito", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: true },
  //{name: 'Indexar Protocolo', code: "indexaProtocolo", tela: "CAIXA_PROTOCOLO_INDEXACAO", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Juntar Documento à Manifestação', code: "juntarDocumentoManifestacao", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_DOCUMENTOS", restrito: false },
  {name: 'Juntar Documento a Processo', code: "juntarDocumentoProcesso", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_DOCUMENTOS", restrito: false },
  {name: 'Juntar Documento (Pendência)', code: "juntarDocumentoPendencia", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_DOCUMENTOS", restrito: false },
  {name: 'Juntar Documento à Manifestação', code: "juntarDocumentoManifestacao", tela: "TELA_CONSULTA_DOCUMENTO", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Juntar Documento a Processo', code: "juntarDocumentoProcesso", tela: "TELA_CONSULTA_DOCUMENTO", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Juntar Documento (Pendência)', code: "juntarDocumentoPendencia", tela: "TELA_CONSULTA_DOCUMENTO", subtela: "SUBTELA_TODOS", restrito: false },
  //{name: 'Migrar Processo Sei', code: "migrarProcessoSei", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: true },
  {name: 'Notificar Interessado', code: "notificarInteressado", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: false },
  {name: 'Oficializar Documento', code: "oficializarDocumento", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_RASCUNHOS", restrito: false },
  //{name: 'Registrar/Acompanhar Notificações', code: "registrarNotificacaoProcesso", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: false },
  {name: 'Registrar Impedimento - Relator', code: "registrarImpedimentoRelator", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: true },
  {name: 'Registrar Impedimento - Relator', code: "registrarImpedimentoRelator", tela: "TELA_CONSULTA_PROCESSO", subtela: "SUBTELA_TODOS", restrito: true },
  {name: 'Registrar Impedimento - Procurador', code: "registrarImpedimentoProcurador", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: true },
  {name: 'Registrar Impedimento - Procurador', code: "registrarImpedimentoProcurador", tela: "TELA_CONSULTA_PROCESSO", subtela: "SUBTELA_TODOS", restrito: true },
  //{name: 'Registrar Digitalização', code: "digitalizaProtocolo", tela: "CAIXA_PROTOCOLO_DIGITALIZACAO", subtela: "SUBTELA_TODOS", restrito: false },
  //{name: 'Registrar Digitalização', code: "digitalizaProtocolo", tela: "CAIXA_PROTOCOLO_DIGITALIZACAO", subtela: "SUBTELA_TODOS", restrito: false },
  //{name: 'Registrar Mídias Físicas', code: "registraMidiaFisicaProtocolo", tela: "CAIXA_PROTOCOLO_DIGITALIZACAO", subtela: "SUBTELA_TODOS", restrito: false },
  //{name: 'Registrar Mídias Físicas', code: "registraMidiaFisicaProtocolo", tela: "CAIXA_PROTOCOLO_INDEXACAO", subtela: "SUBTELA_TODOS", restrito: false },
  //{name: 'Reindexar Documento', code: "reindexarDocumento", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_DOCUMENTOS", restrito: false },
  //{name: 'Reindexar Documento', code: "reindexarDocumento", tela: "TELA_CONSULTA_DOCUMENTO", subtela: "SUBTELA_DOCUMENTO", restrito: false },
  {name: 'Rejeitar Recebimento', code: "rejeitarRecebimento", tela: "CAIXA_ENTRADA_PESSOAL", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Rejeitar Recebimento', code: "rejeitarRecebimento", tela: "CAIXA_ENTRADA_UNIDADE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Rejeitar Solicitação', code: "rejeitarSolicitacao", tela: "CAIXA_SOLICITACAO_RECEBIDA_PESSOAL", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Rejeitar Solicitação', code: "rejeitarSolicitacao", tela: "CAIXA_SOLICITACAO_RECEBIDA_UNIDADE", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Remover Relator', code: "removerRelatorProcesso", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: true },
  {name: 'Remover Relator', code: "removerRelatorProcesso", tela: "TELA_CONSULTA_PROCESSO", subtela: "SUBTELA_TODOS", restrito: true },
  {name: 'Renomear Documento', code: "renomearDocumento", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_DOCUMENTOS", restrito: false },
  {name: 'Renomear Documento', code: "renomearDocumento", tela: "TELA_CONSULTA_DOCUMENTO", subtela: "SUBTELA_TODOS", restrito: false },
  //{name: 'Restaurar Peça Processo/Documento', code: "restaurarPecaProcesso", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_DOCUMENTOS", restrito: false },*/
  {name: 'Retirar do Estoque Inativo', code: "retirarSobrestado", tela: "CAIXA_TRABALHO_UNIDADE", subtela: "SUBTELA_PROCESSOS", restrito: false },
  {name: 'Retirar do Estoque Inativo', code: "retirarSobrestado", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: false },
  {name: 'Solicitar Assinatura', code: "solicitarAssinatura", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_RASCUNHOS", restrito: false },
  {name: 'Solicitar Ciência', code: "solicitarCiencia", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_DOCUMENTOS", restrito: false },
  {name: 'Solicitar Ciência', code: "solicitarCiencia", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_MANIFESTACOES", restrito: false },
  //{name: 'Solicitar Informação ao Demandante', code: "solicitarInformacaoManifestacao", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_MANIFESTACOES", restrito: true },
  {name: 'Solicitar Visto', code: "solicitarVisto", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Substituir Documento de Autuação do Processo', code: "trocarDocumentoAutuaProcesso", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_DOCUMENTOS", restrito: true },
  {name: 'Tornar Peça Sem Efeito', code: "tornarPecaSemEfeito", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: false },
  //{name: 'Retirar de Sobrestado', code: "retirarSobrestado", tela: "CAIXA_MANIFESTACAO_SOBRESTADO", subtela: "SUBTELA_TODOS", restrito: false },
  //{name: 'Validar Protocolo', code: "validarProtocolo", tela: "CAIXA_PROTOCOLO_VALIDACAO", subtela: "SUBTELA_TODOS", restrito: false },
  //{name: 'Validar Protocolo', code: "validarProtocolo", tela: "CAIXA_PROTOCOLO_VALIDACAO", subtela: "SUBTELA_TODOS", restrito: false },
  {name: 'Verificar Qualidade do Protocolo', code: "verificarQualidadeProtocolo", tela: "CAIXA_PROTOCOLO_PORTAL_VERIFICAR_QUALIDADE", subtela: "SUBTELA_TODOS", restrito: false },
  //{name: 'Verificar Qualidade do Protocolo', code: "corrigirProtocoloPortal", tela: "CAIXA_PROTOCOLO_PORTAL_ANALISE", subtela: "SUBTELA_TODOS", restrito: false },
  //{name: 'Vincular Processo Físico', code: "vincularProcessoFisico", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_PROCESSOS", restrito: true },
  //{name: 'Voltar para Etapa Anterior', code: "voltaEtapaAnteriorProt", tela: "CAIXA_PROTOCOLO_DIGITALIZACAO", subtela: "SUBTELA_TODOS", restrito: false },
  //{name: 'Voltar para Etapa Anterior', code: "voltaEtapaAnteriorProt", tela: "CAIXA_PROTOCOLO_INDEXACAO", subtela: "SUBTELA_TODOS", restrito: false },*/
  //{name: 'Voltar para Etapa Análise de Protocolo', code: "voltarDocEtapaAnaliseProtocolo", tela: "CAIXA_TRABALHO_PESSOAL", subtela: "SUBTELA_DOCUMENTOS", restrito: true },
]