import {SPEDE_VISUALIZADOR_URL, showError} from "@/global"
import LoginService from "../service/LoginService";
import SpedeService from "../service/SpedeService";
import operacoes from '@/data/operacoes';

export default {
    install: (app) => {
        app.config.globalProperties.$loginService = new LoginService(),
        app.config.globalProperties.$spedeService = new SpedeService(),
        app.config.globalProperties.$formatarDataHora = function (date) {
            if (!date) return null;
            var result = date
                .match(/\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}/g)
                .toString()
                .split("-")
                .join()
                .split(":")
                .join()
                .split("T");
            var data = result[0].split(",");
            var hour = result[1].split(",");
            data = data[2] + "/" + data[1] + "/" + data[0];
            hour = hour[0] + ":" + hour[1] + ":" + hour[2];
            return data + "  " + hour;
        },

        app.config.globalProperties.$formatarData = function(date) {
          if (!date) return null;
          var result = date.match(/\d{4}-\d{2}-\d{2}/g).toString().split("-");
            return result[2] + "/" + result[1] + "/" + result[0];
        }

        app.config.globalProperties.$formatarDataHoraFromCalendar = function (date) {
          if (!date) return null;
          var strDate = date+"";
          if (strDate.length > 19){
              var dt = new Date(strDate),
              mnth = ("0" + (dt.getMonth() + 1)).slice(-2),
              day = ("0" + dt.getDate()).slice(-2);
              if (dt.getHours()){
                let hours  = ("0" + dt.getHours()).slice(-2);
                let minutes = ("0" + dt.getMinutes()).slice(-2);
                let seconds = ("0" + dt.getSeconds()).slice(-2);
                date = [dt.getFullYear(), mnth, day].join("-") + "T" + [hours, minutes, seconds].join(":");
              }
              else{
                date = [dt.getFullYear(), mnth, day].join("-") + "T" + ["00", "00", "00" ].join(":");
              }
          }
          return date;
        }

        app.config.globalProperties.$formatarDataFromCalendar = function (date) {
          if (!date) return null;
          var strDate = date+"";
          if (strDate.length > 19){
              var dt = new Date(strDate),
              mnth = ("0" + (dt.getMonth() + 1)).slice(-2),
              day = ("0" + dt.getDate()).slice(-2);
              date= [dt.getFullYear(), mnth, day].join("-");
          }
          return date;
        }

        app.config.globalProperties.$formatarDataFromCalendarParaRelatorio = function (date) {
          if (!date) return null;
          var strDate = date+"";
          if (strDate.length > 19){
              var dt = new Date(strDate),
              mnth = ("0" + (dt.getMonth() + 1)).slice(-2),
              day = ("0" + dt.getDate()).slice(-2);
              date= [mnth, day, dt.getFullYear()].join("/");
          }
          return date;
        }

        app.config.globalProperties.$imprimirPagina =  function () {
          window.print();
        },

        app.config.globalProperties.$exibirArquivos = function (idEscrito) {
            // window.open(SPEDE_VISUALIZADOR_URL + "/viewescrito?sessionId=" + this.$loginService.getUserToken() + "&tipoview=escrito" + "&id=" + idEscrito, "_blank");
            window.open(SPEDE_VISUALIZADOR_URL + "/viewescrito?tipoview=escrito" + "&id=" + idEscrito, "_blank");
            /*this.$openNewTab("viewescrito", {
                tipoview:'escrito',
                id: idEscrito
             });*/
        },
        app.config.globalProperties.$openNewTab = function(name, param) {
            let routeData = this.$router.resolve({
              name: name,
              query: param,
            });
            window.open(routeData.href, "_blank");
        }
        app.config.globalProperties.$openNewTabUrl = function(url) {
          window.open(url, "_blank");
        }

      /*Remove parenteses, hifens, pontos, espacos  seja dígito */
      app.config.globalProperties.$formatarDigitos = function (number) {
        if (number != null) {
          let expression = /[() -/ .]/
          let result = number.split(expression)
          let splits = result[0];

          for (let i = 1; i < result.length; i++) {
            splits = splits + result[i];
          }

          return splits;
        } else {
          return null;
        }
      },

      app.config.globalProperties.$vistaDeLinhaDocumento = function (id, cod) {
        this.$router.push({ path: 'detalhedocumento', query: { id: id, cod: cod } })
      }

      app.config.globalProperties.$vistaDeLinhaProcesso = function (id, cod) {
        this.$router.push({ path: 'detalheprocesso', query: { id: id, cod: cod } });
      },

      app.config.globalProperties.$vistaDeLinhaEmbargoDeclaracao = function (id, cod) {
        this.$router.push({ path: 'embargodeclaracao', query: { id: id, cod: cod } });
      },

      app.config.globalProperties.$vistaDeLinhaManifestacao = function (id, cod) {
        this.$router.push({ path: 'detalhemanifestacao', query: { id: id, cod: cod } })
      },

      app.config.globalProperties.$vistaDeLinhaProtocolo = function (id, cod) {
        this.$router.push({ path: 'detalheprotocolo', query: { id: id, cod: cod } })
      },

      app.config.globalProperties.$vistaDeLinha = function (tipoEscrito, id, cod) {
        switch (tipoEscrito) {
          case "PROCESSO":
            this.$vistaDeLinhaProcesso(
              id, cod
            );
            break;
          case "DOCUMENTO":
          case "RASCUNHO":
            this.$vistaDeLinhaDocumento(id, cod);
            break;
          case "PROTOCOLO":
            this.$vistaDeLinhaProtocolo(id, cod);
            break;
          case "MANIFESTACAO":
            this.$vistaDeLinhaManifestacao(
              id, cod
            );
        }
      },

      app.config.globalProperties.$imagemEscrito = function (tipoEscrito, eletronico) {
        switch (tipoEscrito) {
          case "PROCESSO":
            if (eletronico)
              return require(`../../public/demo/images/icons/proceletronico-20x20.png`);
            else
              return require(`../../public/demo/images/icons/procfisico-20x20.png`);
          case "DOCUMENTO":
            return require(`../../public/demo/images/icons/documento-20x20.png`);
          case "RASCUNHO":
            return require(`../../public/demo/images/icons/rascunho-20x20.png`);
          case "MANIFESTACAO":
            return require(`../../public/demo/images/icons/manifestacao-20x20.png`);
        }
      },

      app.config.globalProperties.$verificaSeRecursoEPermitido = function (recurso) {
        let recursos = this.$loginService.getUserPermissoesSetorSelecionado();
        //console.log(recursos);
        if (recurso == null) return true;
        if (recurso.length == 0 || recurso == "") return true;
        for (let rec of recursos) {
          if (rec.operacao == recurso) {
            //console.log("Verifica recurso " + recurso + " true");
            return true;
          }
        }
        //console.log("Verifica recurso " + recurso + " false");
        return false;
      },
      app.config.globalProperties.$isUsuarioLocalResponsavelEscrito = function (escrito) {
        if (escrito.usuarioLocalResponsavel.loginUsuario == this.$loginService.getUser().username &&
          escrito.usuarioLocalResponsavel.idLocal == this.$loginService.getUserSetorSelecionado()) {
          return true;
        }
        return false;
      },

      app.config.globalProperties.$isLocalResponsavelEscrito = function (escrito) {
        if (escrito.usuarioLocalResponsavel.idLocal == this.$loginService.getUserSetorSelecionado()) {
          return true;
        }
        return false;
      },

      app.config.globalProperties.$listarOperacoes = function (tela, subtela) {
        //console.log("$listarOperacoes");
        let lista = [];
        for (let operacao of operacoes) {
          if (operacao.tela == tela && (operacao.subtela == subtela || operacao.subtela == "SUBTELA_TODOS")) {
            //console.log("operacao: " + operacao.code + " - " + operacao.restrito);
            if (!operacao.restrito) {
              lista.push(operacao);
            }
            else {
              if (this.$verificaSeRecursoEPermitido(operacao.code))
                lista.push(operacao);
            }
          }
        }
        return lista.sort();
      }

    app.config.globalProperties.$addOperacaoListarOperacoes = function (lista, codeOperacao) {
      for (let operacao of lista) {
        if (operacao.code == codeOperacao) {
          return lista;
        }
      }
      for (let operacao of operacoes) {
        if (operacao.code == codeOperacao) {
          lista.push(operacao);
        }
      }
      return lista.sort();
    }

    app.config.globalProperties.$addOperacoesListarOperacoes = function (lista, listaAdd) {
      let dicOperacaoAtual = [];
      for (let operacao of lista) {
        dicOperacaoAtual[operacao.code] = operacao;
      }
      let dicOperacoes = [];
      for (let operacao of operacoes) {
        dicOperacoes[operacao.code] = operacao;
      }

      for (let op of listaAdd) {
        if (dicOperacaoAtual[op.code] == null) {
          if (dicOperacoes[op.code] != null)
            lista.push(dicOperacoes[op.code]);
        }
      }
      return lista.sort();
    }

    app.config.globalProperties.$retornarSelectedIds = function (listaSelectedItems) {
      let listaSelectedId = [];

      // converte de Map para Array e copia somente os ids para um novo array
      Array.from(listaSelectedItems).forEach(function (item) {
        listaSelectedId.push(item.id);
      });
      return listaSelectedId;

    }

    app.config.globalProperties.$limitarCaracteres = function (string, limit) {
      if (string.length > limit) {
        return string.substring(0, limit) + ' ...';
      } else {
        return string;
      }
    }
    app.config.globalProperties.$removeItemArray = function(itemRemove, arrayList){
      let index = 0;
      if (itemRemove && arrayList){
        for (let item of arrayList){
          if (item.id == itemRemove.id){
                arrayList.splice(index, 1);
                break;
          }
          index++;
        }
      }
      return arrayList;
    }

    app.config.globalProperties.$updateItemArray = function(itemUpdate, arrayList, campoCompara){
      let index = 0;
      if (itemUpdate && arrayList){
        for (let item of arrayList){
          if (item[campoCompara] == itemUpdate[campoCompara]){
            arrayList[index] = itemUpdate;
          }
          index++;
        }
      }
      return arrayList;
    }

    app.config.globalProperties.$getStringFromListItem = function (listaItem){
      if (listaItem){
        let listaIDManter = "";
        for (let item of listaItem){
          listaIDManter = listaIDManter + (listaIDManter.length > 0? ",": "");
          listaIDManter = listaIDManter + item.id;
        }
        return listaIDManter;
      }
      return "";
		}
    app.config.globalProperties.$addItemListString = function (item, listStr){
      if (item){
        listStr = listStr + (listStr.length > 0? ",": "");
        listStr = listStr + item.id;
      }
      return listStr;
		}

    app.config.globalProperties.$isEmpty = function (object) {
      return Object.keys(object).length === 0;
    }

    app.config.globalProperties.$nomeTela = function(rota) {
      switch(rota.path.split("/")[1]){
        case "anexardocumento":
            return "Anexar Documento";
        case "enviartramitar":
            return "Enviar (Tramitar)";
        case "juntardocumentopendencia":
            return "Juntar Documento (Pendência)";
        case "juntardocumentoprocesso":
            return "Juntar Documento a Processo";
        case "registrarimpedimentorelator":
              return "Registrar Impedimento - Relator";
        case "registrarimpedimentoprocurador":
              return "Registrar Impedimento - Procurador"
        case "juntardocumentomanifestacao":
            return "Juntar Documento à Manifestação";
        case "apensarprocesso":
            return "Apensar Processo";
        case "desapensarprocesso":
            return "Desapensar Processo";
        case "novonumerodocumento":
            return "Novo Número de Documento";
        case "autuardocumento":
              return "Autuar Documento";
        case "autuarmanifestacao":
              return "Autuar Manifestação";
        case "novoprocesso":
              return "Novo Processo";
        case "importardocumento":
            return "Importar Documento";
        case "novodocumentointerno":
            return "Novo Documento Interno";
        case "embargodeclaracao":
            return "Embargo de Declaração";
        case "solicitarassinatura":
            return "Solicitar Assinatura";
        case "solicitarvisto":
             return "Solicitar Visto";
        case "solicitarciencia":
            return "Solicitar Ciência";
        case "atendersolicitacaoassinatura":
            return "Atender Solicitação de Assinatura";
        case "atendersolicitacaovisto":
              return "Atender Solicitação de Visto";
        case "atendersolicitacaociencia":
             return "Atender Solicitação de Ciência";
        case "rejeitarsolicitacao":
            return "Rejeitar Solicitação";
        case "cancelarsolicitacao":
            return "Cancelar Solicitação";
        case "assinardocumento":
            return "Assinar Documento";
        case "oficializardocumento":
            return "Oficializar Documento";
        case "confirmarrecebimento":
            return "Confirmar Recebimento";
        case "rejeitarrecebimento":
            return "Rejeitar Recebimento";
        case "cancelarenvio":
              return "Cancelar Envio";
        case "detalheprocesso":
            return "Processo " + rota.query['cod'];
        case "detalhedocumento":
            if (rota.query['cod'].endsWith("X"))
              return "Rascunho " + rota.query['cod'];
            else
              return "Documento " + rota.query['cod'];
        case "detalheprotocolo":
                return "Protocolo " + rota.query['cod'];
        case "detalhemanifestacao":
            return "Manifestação " + rota.query['cod'];
        case "comentar":
            return "Comentar";
        case "alterarmarcadores":
            return "Alterar Marcadores";
        case "consultaprocessoresultado":
            return "Resultado da Consulta de Processo";
        case "consultadocumentoresultado":
            return "Resultado da Consulta de Documento";
        case "consultanumerodocumentoresultado":
            return "Resultado da Consulta de Número de Documento";
        case "consultaprotocoloresultado":
            return "Resultado da Consulta de Protocolo";
        case "cancelarnumerodocumento":
            return "Cancelar Número de Documento";
        case "excluirdocumento":
            return "Excluir Documento";
        case "arquivardocumento":
              return "Arquivar Documento";
        case "arquivarprocesso":
              return "Arquivar Processo";
        case "sobrestarescrito":
              return "Incluir no Estoque Inativo";
        case "retirarsobrestado":
              return "Retirar de Estoque Inativo";
        case "arquivarmanifestacao":
              return "Arquivar Manifestação";
        case "reabrirdocumento":
              return "Desarquivar Documento";
        case "reabrirprocesso":
              return "Desarquivar Processo";
        case "reabrirmanifestacao":
              return "Desarquivar Manifestação";
        case "processarmanifestacao":
              return "Enviar Manifestação para Caixa de Trabalho";
        case "processarprotocoloportal":
              return "Enviar Manifestação para Caixa de Trabalho";
        case "listarnotificacaoprocesso":
          return "Controle de Registro de Notificações de Processo";
        case "registrarnotificacaoprocesso":
          return "Registrar Notificação de Processo";
        case "enviarjulgamento":
          return "Enviar Processo para Julgamento";
        case "consultamanifestacaoresultado":
          return "Resultado da Consulta de Manifestação";
        case "solicitarinformacaomanifestacao":
            return "Solicitar Informação ao Demandante";
        case "novamanifestacao":
          return "Nova Manifestação";
        case "substituirdocumentoautuacao":
          return "Substituir Documento de Autuacao";
        case "verificarqualidadeprotocolo":
          return "Verificar Qualidade do Protocolo";
        case "cancelarprotocolo":
          return "Cancelar Protocolo";
        case "renomeardocumento":
          return "Renomear Documento";
        case "finalizarmanifestacao":
          return "Finalizar Manifestação";
        case "admmunicipio":
          return "Munícipios";
        case "admlocalinterno":
          return "Setores do TCE";
        case "admhierarquialocal":
            return "Hierárquia de Setores do TCE";
        case "admitensverificacao":
            return "Itens de Verificação"
        case "admmotivosituacaoescrito":
          return "Motivos das Situações de Escrito"
        case "alterarfase":
          return "Alterar Fase";
        case "admdocumentotipo":
          return "Tipos de Documento";
        case "admperfil":
          return "Perfil";
        case "admdocumentoassunto":
          return "Assuntos de Documento";
        case "admnaturezas":
          return "Naturezas de Processo"
        case "removerrelatorprocesso":
          return "Remover Relator do Processo";
        case "admsolicitacaorecusa":
          return "Motivos de Recusa das Solicitações";
        case "admtramitacaoenvio":
          return "Motivos de Envio das Tramitações";
        case "admtramitacaorecusa":
          return "Motivos de Recusa das Tramitações";
        case "admmanifestacaotipo":
          return "Tipos de Manifestação";
        case "admmanifestacaomodo":
          return "Modos de Manifestação";
        case "admmanifestacaoasssunto":
          return "Assuntos de Manifestação";
        case "admrelacaonatureza":
          return "Relações entre Naturezas";
        case "admdirecaotribunal":
          return "Direção do Tribunal"
        case "admcomissaoinspecao":
          return "Comissões de Inspeção"
        case "definirLocalizacaoFisica":
          return "Definir Localização Física";
        case "admprocessopapeis":
          return "Papéis por Natureza";
        case "admprocessoespecie":
          return "Espécie por Natureza";
        case "distribuirprocessoprocuradoria":
          return "Distribuir Processo (Procuradoria)";
        case "distribuirprocessorelator":
          return "Distribuir Processo (Relator)";
        case "admdocumentonumeracaoheranca":
          return "Herança na Numeração de Documentos";
        case "admdocumentonumeracao":
          return "Numeração de Documentos";
        case "admdocumentonatureza":
          return "Pontos por Tipo de Documento x Natureza";
        case "admdocumentomodelo":
          return "Modelos de Documentos";
        case "admlistarelatores":
          return "Relatores";
        case "admlistaprocuradores":
          return "Procuradores";
        case "admpessoajuridica":
          return "Pessoas Jurídicas";
        case "admtipodistribuicaonatureza":
          return "Tipos de Distribuição por Natureza";
        case "admpessoafisica":
          return "Pessoa Física";
        case "admconfiguracaogeral":
          return "Configuração Geral";
        case "admconfiguracaoemail":
          return "Configuração de Email";
        case "admmotivosobrestamento":
          return "Motivos de Sobrestamento de Escrito";
        case "admprocuradoria":
          return "Procuradorias";
        case "admnaturezaespecietipodeliberacao":
          return "Natureza/Espécie dos Tipos de Deliberação";
        case "admtipodeliberacaopornaturezaespecie":
          return "Tipos de Deliberação por Natureza/Espécie";
        case "admtipodeliberacao":
          return "Tipos de Deliberação";
        case "admmodelosdocumentodecisorio":
          return "Modelos de Documentos Decisório";
        case "admfundamentacaolegal":
          return "Fundamentação Legal";
        case "admperiododecontagemdeprocessos":
          return "Período de Contagem de Processos";
        case "admhorasdecompensacaodeprocurador":
          return "Horas de Compensação de Procurador";
        case "admitensverificacaoporcategoria":
          return "Itens de Verificação por Categoria";
        case "admgerenciamentoperfilusuario":
          return "Usuários";
        case "admrecursos":
          return "Recursos";
        case "admprogramaestoqueexcedente":
          return "Programa de Redução de Estoque Excedente";
        case "admvincularusuario":
          return "Vinculação de Usuário a Perfis";
        case "admvincularusuarioporlocal":
          return "Vinculação de Usuário a Perfis (por Local)";
        case "admareadistribuicao":
          return "Áreas de Distribuição"
        case "admareadistribuicaoprocurador":
            return "Áreas de Distribuição de Procurador"
        case "admareadistribuicaorelator":
            return "Áreas de Distribuição de relator";
        case "admcorcapa":
            return "Cores de Capa";
        case "historicousuario":
            return "Histórico do Usuário";
        case "notificarinteressado":
            return "Notificar Interessado";
        case "consultanotificacaoresultado":
            return "Resultado da Consulta de Notificação";
        case "enviardocumentosporemail":
            return "Enviar documentos por e-mail";
        case "adicionarPendencia":
            return "Adicionar Pendência";
        case "admprocuradoriaporprocurador":
            return "Procuradoria Por Procurador";
        default:
          return "";
      }
    }
    app.config.globalProperties.$getLocalUsuario = function(local, usuario) {
      let setor = this.$loginService.getUserNomeSetorSelecionado();
      if (usuario){
          if (local != setor){
            return local + " / " + usuario;
          }
          else{
            return usuario + " / " + local;
          }
      }
      else{
        return local;
      }
    }
    app.config.globalProperties.$arrayRemove = function(arr, value) {
      return arr.filter(function(ele){
        return ele != value;
      });
    }
    app.config.globalProperties.$toCurrency = function (value) {
      if (value && value>0){
        var formatter = new Intl.NumberFormat('pt-BR', {
          style: 'currency',
          currency: 'BRL',
          // These options are needed to round to whole numbers if that's what you want.
          //minimumFractionDigits: 2, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
          //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
        });
        value = formatter.format(value); /* $2,500.00 */
        return value;
      }
      else{
        return null;
      }
    }
    app.config.globalProperties.$inicializaHistoricoTela = function(nomeTela) {
      this.$store.state.historicoTela = [];
      this.$store.state.historicoTela.push({tela:nomeTela, rota:this.$route});
    }
    app.config.globalProperties.$letOnlyRootPathHistoricoTela = function() {
      if (this.$store.state.historicoTela.length > 0){
          if (this.$store.state.historicoTela[this.$store.state.historicoTela.length-1].rota.name == 'consultaprocessoresultado'){
              //nao faz nada
          }
          else if (this.$store.state.historicoTela[0].rota.name == 'dashboard'){
              this.$store.state.historicoTela = this.$store.state.historicoTela.splice(0,1);
          }
          else{
            this.$store.state.historicoTela = [];
          }
      }
    }
    app.config.globalProperties.$getPreviousRootPathHistoricoTela = function() {
      let tamanho = this.$store.state.historicoTela.length;
      return this.$store.state.historicoTela[tamanho-2];
    }
    app.config.globalProperties.$updateHistoricoTela = function(fullPathAntigo, rotaNova) {
      let i =0;
      for (let tela of this.$store.state.historicoTela){
          if (tela.rota.fullPath == fullPathAntigo){
            this.$store.state.historicoTela[i] = rotaNova;
          }
          i++;
      }
    }
    app.config.globalProperties.$addRotaHistoricoTela = function() {
        /*if (this.$store.state.historicoTela.length == 0 && this.$route.path!='/dashboard'){
            this.$store.state.historicoTela.push({tela:this.$store.state.nomeTela, rota:{ path: '/dashboard'}} );
        }*/
        /*switch(this.$route.name){
          case "dashboard":
          case "detalheprocesso":
          case "detalhedocumento":
          case "detalhemanifestacao":
          case "detalheprotocolo":
          case "cancelarnumerodocumento":
          case "registrarnotificacaoprocesso":
              break; //nao faz nada
          default:
            this.$letOnlyRootPathHistoricoTela();
        }*/
        if (this.$route.name.startsWith('adm')){ //adminitrador
          //this.$letOnlyRootPathHistoricoTela();
          this.$store.state.historicoTela = [];
        }
        else{
            switch(this.$route.name){
              case "novodocumentointerno":
              case "novonumerodocumento":
              case "embargodeclaracao":
              case "importardocumento":
              case "consultadocumentoresultado":
              case "consultaprotocoloresultado":
              case "consultanumerodocumentoresultado":
              case "consultaprocessoresultado":
              case "listarnotificacaoprocesso":
              case "consultanotificacaoresultado":
                //this.$letOnlyRootPathHistoricoTela();
                this.$store.state.historicoTela = [];
                break;
              default:
            }
          }
        let achou = false;
        let i =0;
        for (let tela of this.$store.state.historicoTela){
            if (tela.rota.fullPath == this.$route.fullPath){
              achou = true;
              this.$store.state.historicoTela = this.$store.state.historicoTela.splice(0,i+1);
            }
            i++;
        }
        if (!achou){
            this.$store.state.historicoTela.push({tela:null, rota:this.$route});
        }
    }

    app.config.globalProperties.$showRetornoErro = function(dataResponse) {
      for (var campoErro in dataResponse) {
        showError(dataResponse[campoErro].erro);
      }
    }

    app.config.globalProperties.$retornarTelaAnterior = function() {
      let rotaAnterior = this.$getPreviousRootPathHistoricoTela();
      if (rotaAnterior){
          this.$router.push(rotaAnterior.rota);
      }
      else{
        this.$router.push('dashboard');
      }
    }

    app.config.globalProperties.$gerarRelatorio = function(parametros) {
      this.$spedeService
        .postGerarRelatorio(parametros)
        .then((res) => {
          var blob = new Blob([res.data], {
            type: "application/pdf",
          });
          var filename = "";
          var disposition = res.headers["content-disposition"];
          if (disposition && disposition.indexOf("inline") !== -1) {
            var filenameRegex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
            var matches = filenameRegex.exec(disposition);
            if (matches != null && matches[1]) {
              filename = matches[1].replace(/['"]/g, "");
            }
          }
          var downloadElement = document.createElement("a");
          var href = window.URL.createObjectURL(blob);
          downloadElement.style.display = "none";
          downloadElement.href = href;
          downloadElement.download = filename;
          document.body.appendChild(downloadElement);
          downloadElement.click();
          document.body.removeChild(downloadElement);
          window.URL.revokeObjectURL(href);
          this.$store.state.dlgLoading = false;
        })
        .catch(error => {
          showError(error.response.headers["spede-msg"]);
        });
    }

  }

}