import api from "../main.js";

export default class SpedeService {
    getEscrito(id) {
        return api
            .get("/api/v2/visualizador/obterEscrito/" + id)
            .then((res) => res.data);
    }

	getMidiaFisicaDocumento(id) {
        return api
            .get("/api/v2/visualizador/midiafisica_documento/" + id)
            .then((res) => res.data);
    }

	getInteressados(id) {
        return api
            .get("/api/v2/visualizador/interessados/" + id)
            .then((res) => res.data);
    }

	getComentarios(id) {
        return api
            .get("/api/v2/visualizador/comentarios/" + id)
            .then((res) => res.data);
    }

	getAssinaturasDoc(id) {
        return api
            .get("/api/v2/visualizador/assinaturas/" + id)
            .then((res) => res.data);
    }

	getVistos(id) {
        return api
            .get("/api/v2/visualizador/vistos/" + id)
            .then((res) => res.data);
    }

	getTramitacao(id) {
        return api
            .get("/api/v2/visualizador/tramitacao/" + id)
            .then((res) => res.data);
    }

	getHistorico(id) {
        return api
            .get("/api/v2/visualizador/historico/" + id)
            .then((res) => res.data);
    }

	getLogsDocumento(id, dataInicio, dataTermino) {
        return api
            .get("/api/v2/visualizador/logs/" + id + "/dataInicio/" + dataInicio + "/dataTermino/" + dataTermino)
            .then((res) => res.data);
    }

	getProcesso(numProcesso, anoProcesso) {
        return api
            .get("/api/v2/visualizador/" + numProcesso + "/ano/" + anoProcesso)
            .then((res) => res.data);
    }

	getMidiasFisicas(id) {
        return api
            .get("/api/v2/visualizador/midiasfisicas/" + id)
            .then((res) => res.data);
    }

	getHierarquiaPaginacaoEscrito(idEscrito) {
        return api
            .get("/api/v2/visualizador/hierarquiaPaginacaoEscrito/" + idEscrito)
            .then((res) => res.data);
    }

    getRecuperarLog(id) {
        return api
            .get("/api/v2/visualizador/recuperarLog/" + id)
            .then((res) => res.data);
    }

	getGerarCapaProcesso(id) {
        return api
            .get("/api/v2/visualizador/gerarCapaProcesso/" + id)
            .then((res) => res.data);
    }

	getArquivoExibir(dadosArquivo, usuario) {
        return api
            .get("/api/v2/visualizador/obterArquivo" + dadosArquivo + "?user=" + usuario, { responseType: 'blob', } )
            .then((res) => res);
    }

	postObterArquivoPDFZip(param) {
        return api
            .post("/api/v2/visualizador/obterArquivoPDFZip", param)
            .then((res) => res.data);
    }

	postObterArquivoPDF(param) {
        return api
            .post("/api/v2/visualizador/obterArquivoPDF", param)
            .then((res) => res.data);
    }

    getRecuperarInfoUsuario() {
        return api
            .get("/api/v2/visualizador/recuperarInfoUsuario")
            .then((res) => res.data);
    }
    getJulgamento(id){
        return api
            .get("/api/v2/visualizador/julgamento/"+ id )
            .then((res) => res.data);
    }

}
