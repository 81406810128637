import getCookie from '@/utils/getCookie';

export default class LoginService {

  clearUserToken() {
    document.cookie = "__knowledge_user_token=; expires=Thu, 01 Jan 1970 00:00:00 UTC; SameSite=Strict";
  }

  getUserToken() {
    return getCookie("__knowledge_user_token");
  }
}
