import { createStore } from 'vuex'
import operacoes from '@/data/operacoes';
import router from '@/router';
import { showError, usuario_permissoes_setor_selecionado, usuario_token } from "@/global";

const store = createStore({
    state() {
      return {
        listaOperacoes: [],
        dlgEditarLocalInterno: false,
        dlgEditarAreaDistribuicao: false,
        dlgEditarPessoaJuridica: false,
        dlgEditarTipoDeliberacoes: false,
        dlgEditarUsuarioUnidade: false,
        dlgSelecionarUsuario: false,
        dlgAdicionarServidor: false,
        parametrosDlgSelecionarPecaProcesso: null,
        dlgSelecionarDocumento: false,
        dlgAdicionarInteressado: false,
        dlgCadastrarPessoaFisica: false,
        dlgCadastrarPessoaJuridica: false,
        dlgAlterarPessoaFisica: false,
        dlgAlterarPessoaJuridica: false,
        dlgCadastrarFases: false,
        dlgCadastrarMarcador: false,
        dlgAdicionarAnexoManifestacao: false,
        dlgConsultaDocumento: false,
        dlgConsultaAvancadaDocumento: false,
        dlgConsultaNumeroDocumento: false,
        dlgRelatorioEstoqueInativo: false,
        registrarImpedimento: null,
        tipoDlgRelatorioEstoqueInativo: '',
        dlgRelatorioProtocolo: false,
        tipodlgRelatorioProtocolo: '',
        dlgRelatorioProdutividade: true,
        paramRelatorioBase: {},
        dlgLoading: false,
        dlgAlertaComissao: false,
        dlgAdicionarEmbargo: false,
        dlgEditarRecurso: false,
        dlgConsultarUsuario: false,
        listaSelecionadosMesaTrabalho: [],
        listaSelecionadosCancelarNumeroDocumento: [],
        tipoCaixa: "CAIXA_ENTRADA",
        escopoCaixa: "CAIXA_PESSOAL",
        telaCaixa: "CAIXA_ENTRADA_PESSOAL",
        nomeTela: "Caixa de Entrada Pessoal",
        tipoPasta: "ENT_TRAB_SAI",
        estadoCaixa: null,
        historicoTela: [],
        valorSelecionadoDropDownComOverlay: null,
        parametrosConsultaAvancadaProcesso: null,
        parametrosConsultaAvancadaDocumento: null,
        parametrosConsultaNumeroDocumento: null,
        parametrosControleRegistroNotificacao: null,
        parametrosConsultaAvancadaManifestacao: null,
        parametrosConsultaAvancadaProtocolo: null,
        parametrosTela: null,
        dlgConsultaNotificacao: false,
        parametrosConsultaNotificacao: null
      }
    },
    getters: {
    },
    mutations: {
      setDlgEditarPessoaJuridica(state, payload) {
        state.dlgEditarPessoaJuridica = payload
      },
      setDlgEditarLocalInterno(state, payload) {
        state.dlgEditarLocalInterno = payload
      },
      setDlgEditarUsuarioUnidade(state, payload) {
        state.dlgEditarUsuarioUnidade = payload
      },
      setDlgAdicionarServidor(state, payload) {
        state.dlgAdicionarServidor = payload
      },
      setParametrosDlgSelecionarPecaProcesso(state, payload) {
        state.parametrosDlgSelecionarPecaProcesso = payload
      },
      setDlgSelecionarDocumento(state, payload) {
        state.dlgSelecionarDocumento = payload
      },
      setDlgSelecionarUsuario(state, payload) {
        state.dlgSelecionarUsuario = payload
      },
      setDlgAdicionarInteressado(state, payload) {
        state.dlgAdicionarInteressado = payload
      },
      setDlgCadastrarPessoaFisica(state, payload) {
        state.dlgCadastrarPessoaFisica = payload
      },
      setDlgAlterarPessoaFisica(state, payload) {
        state.dlgAlterarPessoaFisica = payload
      },
      setDlgCadastrarPessoaJuridica(state, payload) {
        state.dlgCadastrarPessoaJuridica = payload
      },
      setDlgAlterarPessoaJuridica(state, payload) {
        state.dlgAlterarPessoaJuridica = payload
      },
      setDlgCadastrarFases(state, payload) {
        state.dlgCadastrarFases = payload
      },
      setDlgCadastrarMarcador(state, payload) {
        state.dlgCadastrarMarcador = payload
      },
      setDlgAdicionarAnexoManifestacao(state, payload) {
        state.dlgAdicionarAnexoManifestacao = payload
      },
      setDlgLoading(state, payload) {
        state.dlgLoading = payload
      },
      setListaSelecionadosMesaTrabalho(state, payload) {
        state.listaSelecionadosMesaTrabalho = payload
      },
      setListaSelecionadosCancelarNumeroDocumento(state, payload) {
        state.listaSelecionadosCancelarNumeroDocumento = payload
      },
      setListaOperacoes(state, payload) {
        state.listaOperacoes = payload
      },
      setParametrosConsultaAvancadaProcesso(state, payload) {
        state.parametrosConsultaAvancadaProcesso = payload
      },
      setParametrosConsultaAvancadaDocumento(state, payload) {
        state.parametrosConsultaAvancadaDocumento = payload
      },
      setParametrosConsultaNumeroDocumento(state, payload) {
        state.parametrosConsultaNumeroDocumento = payload
      },
      setParametrosControleRegistroNotificacao(state, payload) {
        state.parametrosControleRegistroNotificacao = payload
      },
      setParametrosConsultaAvancadaManifestacao(state, payload) {
        state.parametrosConsultaAvancadaManifestacao = payload
      },
      setParametrosConsultaAvancadaProtocolo(state, payload) {
        state.parametrosConsultaAvancadaProtocolo = payload
      },
      setRegistrarImpedimento(state, payload) {
        state.registrarImpedimento = payload
      },
      setParametrosTela(state, payload) {
        state.parametrosTela = payload;
      },
      setParametrosConsultaNotificacao(state, payload) {
        state.parametrosConsultaNotificacao = payload
      },
    },
    actions: {
      listarOperacoes({ commit }, payload){
        /* PERMISSOES */
        var jsonPermissoes = localStorage.getItem(usuario_permissoes_setor_selecionado);
        var permissoes = [];
        if (jsonPermissoes != null) {
          permissoes = JSON.parse(jsonPermissoes);
        }
        var lista = [];
        for (let operacao of operacoes) {
          if (operacao.tela == payload.tela && (operacao.subtela == payload.subtela || operacao.subtela == "SUBTELA_TODOS")){
              if (!operacao.restrito) {
                if(lista.find(x => x.code == operacao.code)) {
                  continue;
                }
                lista.push(operacao);
              }
              else {
                for (let rec of permissoes) {
                  if (rec.operacao == operacao.code) {
                    if(lista.find(x => x.code == operacao.code)) {
                      continue;
                    }
                    lista.push(operacao);
                  }
                }
              }
          }
        }
        commit('setListaOperacoes', lista)
      },
      exeOperacao({ commit }, payload){
            var novaLista = [];
            var requerNovaLista = false;
            if (!payload.selectedMesaTrabalho.length){
              showError("Para executar a operação '" + payload.operacaoSelecionada.name + "', você deve selecionar pelo menos um item válido da lista.");
              return;
            }
            if (!payload.operacaoSelecionada) {
                showError('Selecione uma operação.')
                return;
            }
            switch (payload.operacaoSelecionada.code){
              //QUALQUER ITEM (LISTA DE ID)
              case "comentar":
              case "gerarVoto":
              case "juntarDocumentoProcesso":
              case "juntarDocumentoPendencia":
              case "registrarImpedimentoProcurador":
              case "registrarImpedimentoRelator":
              case "juntarDocumentoManifestacao":
              case "anexarDocumento":
              case "definirLocalizacaoFisica":
              case "renomearDocumento":
              case "distribuirProcesso":
              case "distribuirProcessoProcuradoria":
              case "removerRelatorProcesso":
              case "adicionarPendencia":
                  for (let item of payload.selectedMesaTrabalho){
                        novaLista.push(item.idEscrito);
                  }
                  requerNovaLista = true;
                  break;
              //APENAS PRINCIPAIS ARQUIVADOS (LISTA DE ID)
              case "reabrirDocumento":
              case "reabrirProcesso":
              case "reabrirManifestacao":
                  for (let item of payload.selectedMesaTrabalho){
                    if (item.papelEscrito && item.papelEscrito == "PRINCIPAL"){
                      if (item.situacaoEscrito == "ARQUIVADO"){
                        novaLista.push(item.idEscrito);
                      }
                    }
                    else{ //Vem da tela detalhe
                        novaLista.push(item.idEscrito);
                    }
                  }
                  requerNovaLista = true;
                  break;
              /*/PRINCIPAIS E APENSOS ATIVOS (LISTA DE ID)
              case "":
                  for (let item of payload.selectedMesaTrabalho){
                    if (item.situacaoEscrito != "SOBRESTADO"){
                        novaLista.push(item.idEscrito);
                    }
                  }
                  requerNovaLista = true;
                  break;
              */
              //APENAS PRINCIPAIS ATIVOS
              case "tramitar":
              case "confirmarRecebimento":
              case "rejeitarRecebimento":
              case "cancelarEnvio":
              case "alterarFase":
              case "arquivarDocumento":
              case "arquivarProcesso":
              case "arquivarManifestacao":
              case "apensarProcesso":
              case "finalizarManifestacao":
              case "sobrestarEscrito":
                    for (let item of payload.selectedMesaTrabalho){
                      if (item.papelEscrito == "PRINCIPAL"){
                        if (item.situacaoEscrito == "" || item.situacaoEscrito == "ABERTO" || item.situacaoEscrito == "REABERTO"){
                          novaLista.push(item);
                        }
                      }
                    }
                    requerNovaLista = true;
                    break;
              //APENAS APENSOS ATIVOS
              case "desapensarProcesso":
                  for (let item of payload.selectedMesaTrabalho){
                    if (item.situacaoEscrito == "VINCULADO"){
                      // && item.processoRaiz && item.processoRaiz.situacaoEscrito != "SOBRESTADO"
                          novaLista.push(item);
                    }
                  }
                  requerNovaLista = true;
                  break;
              //APENAS PRINCIPAIS EM SOBRESTADO
              case "retirarSobrestado":
                  for (let item of payload.selectedMesaTrabalho){
                    if (item.situacaoEscrito == "SOBRESTADO"){
                        novaLista.push(item);
                    }
                  }
                  requerNovaLista = true;
                  break;
              //QUALQUER ITEM ESCRITO ATIVO (APENAS 1 UNIDADE)
              case "autuarManifestacao":
              case "solicitarInformacaoManifestacao":
                  for (let item of payload.selectedMesaTrabalho){
                    if (item.situacaoEscrito == "ABERTO" || item.situacaoEscrito == "REABERTO"){
                        novaLista.push(item);
                        break;
                    }
                  }
                  requerNovaLista = true;
                  break;
            }

            if (requerNovaLista && novaLista.length == 0){
              showError("Selecione pelo menos um item válido para a operação '"+payload.operacaoSelecionada.name+"'.");
              return;
            }

            switch (payload.operacaoSelecionada.code){
              case "alterarProcesso":
              case "alterarMetadadoProcesso":
                  router.push({ path: 'detalheprocesso', query: { id: payload.selectedMesaTrabalho[0].idEscrito, cod: payload.selectedMesaTrabalho[0].codigoEscrito, edit: true, operacao:  payload.operacaoSelecionada.code} });
                  break;
              case "alterarDocumento":
                  router.push({ path: 'detalhedocumento', query: { id: payload.selectedMesaTrabalho[0].idEscrito, cod: payload.selectedMesaTrabalho[0].codigoEscrito, edit: true } });
                  break;
              case "alterarManifestacao":
                  router.push({ path: 'detalhemanifestacao', query: { id: payload.selectedMesaTrabalho[0].idEscrito, cod: payload.selectedMesaTrabalho[0].codigoEscrito, edit: true } });
                  break;
              case "atenderSolicitacaoAssinatura":
                  commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                  router.push('atendersolicitacaoassinatura');
                  break;
              case "atenderSolicitacaoCiencia":
                  commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                  router.push('atendersolicitacaociencia');
                  break;
              case "atenderSolicitacaoVisto":
                  commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                  router.push('atendersolicitacaovisto');
                  break;
              case "rejeitarSolicitacao":
                  commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                  router.push('rejeitarsolicitacao');
                  break;
              case "cancelarSolicitacao":
                  commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                  router.push('cancelarsolicitacao');
                  break;
              case "assinarDocumento":
                  commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                  router.push('assinardocumento');
                  break;
              case "oficializarDocumento":
                  commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                  router.push('oficializardocumento');
                  break;
              case "solicitarAssinatura":
                  commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                  router.push('solicitarassinatura');
                  break;
              case "solicitarVisto":
                    commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                    router.push('solicitarvisto');
                    break;
              case "solicitarCiencia":
                    commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                    router.push('solicitarciencia');
                    break;
              case "processarManifestacao":
                      commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                      router.push('processarmanifestacao');
                      break;
              case "processarProtocoloPortal":
                      commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                      router.push('processarprotocoloportal');
                      break;
              case "notificarInteressado":
                      commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                      router.push('notificarinteressado');
                      break;
              case "definirLocalizacaoFisica":
                      commit('setListaSelecionadosMesaTrabalho', novaLista);
                      router.push('definirLocalizacaoFisica');
                      break;
              case "gerarVoto":
                      var userToken = localStorage.getItem(usuario_token);
                      window.open(process.env.VUE_APP_URL_JULGAMENTO + "/SpedePlus/pages/dashboardSpede.jsf?sessionId="+ userToken  +"&idProcesso="+ novaLista[0] + "&vo=false");
                      break;
              case "juntarDocumentoManifestacao":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('juntardocumentomanifestacao');
                    break;
              case "juntarDocumentoProcesso":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('juntardocumentoprocesso');
                    break;
              case "juntarDocumentoPendencia":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('juntardocumentopendencia');
                    break;
              case "registrarImpedimentoRelator":
                      commit('setListaSelecionadosMesaTrabalho', novaLista);
                      commit('setRegistrarImpedimento', 'relator');
                      router.push('registrarimpedimentorelator');
                      break;
              case "registrarImpedimentoProcurador":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    commit('setRegistrarImpedimento', 'procurador');
                    router.push('registrarimpedimentoprocurador');
                    break;
              case "tramitar":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('enviartramitar');
                    break;
              case "arquivarDocumento":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('arquivardocumento');
                    break;
              case "arquivarProcesso":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('arquivarprocesso');
                    break;
              case "arquivarManifestacao":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('arquivarmanifestacao');
                    break;
              case "finalizarManifestacao":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('finalizarmanifestacao');
                    break;
              case "reabrirDocumento":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('reabrirdocumento');
                    break;
              case "reabrirProcesso":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('reabrirprocesso');
                    break;
              case "apensarProcesso":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('apensarprocesso');
                    break;
              case "desapensarProcesso":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('desapensarprocesso');
                    break;
              case "sobrestarEscrito":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('sobrestarescrito');
                    break;
              case "retirarSobrestado":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('retirarsobrestado');
                    break;
              case "reabrirManifestacao":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('reabrirmanifestacao');
                    break;
              case "confirmarRecebimento":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('confirmarrecebimento');
                    break;
              case "enviarDocumentosPorEmail":
                    commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                    router.push('enviardocumentosporemail');
                    break;
                case "rejeitarRecebimento":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('rejeitarrecebimento');
                    break;
                case "cancelarEnvio":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('cancelarenvio');
                    break;
                case "cancelarProtocolo":
                      commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                      router.push('cancelarprotocolo');
                      break;
                case "comentar":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('comentar');
                    break;
                case "alterarMarcadores":
                    commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                    router.push('alterarmarcadores');
                    break;
                case "cancelarNumeroDocumento":
                    commit('setListaSelecionadosCancelarNumeroDocumento', payload.selectedMesaTrabalho);
                    router.push('cancelarnumerodocumento');
                    break;
                case "excluirDocumento":
                    commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                    router.push('excluirdocumento');
                    break;
                case "anexarDocumento":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('anexardocumento');
                    break;
                case "enviarJulgamento":
                    commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                    router.push('enviarjulgamento');
                    break;
                case "autuarDocumento":
                    commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                    router.push('autuardocumento');
                    break;
                case "autuarManifestacao":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('autuarmanifestacao');
                    break;
                case "tornarPecaSemEfeito":
                    var name = "viewescrito";
                    var param = { tipoview:'escrito',
                                  id: payload.selectedMesaTrabalho[0].idEscrito,
                                  tipoOperacao: 'tornarSemEfeito'
                                };
                    var routeData = router.resolve({
                      name: name,
                      query: param,
                    });
                    window.open(routeData.href);
                  break;
                case "trocarDocumentoAutuaProcesso":
                    commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                    router.push('substituirdocumentoautuacao');
                    break;
                case "verificarQualidadeProtocolo":
                    commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                    commit('setParametrosTela', payload.parametrosTela);
                    router.push('verificarqualidadeprotocolo');
                    break;
                case "solicitarInformacaoManifestacao":
                      commit('setListaSelecionadosMesaTrabalho', novaLista);
                      router.push('solicitarinformacaomanifestacao');
                    break;
                case "renomearDocumento":
                      commit('setListaSelecionadosMesaTrabalho', novaLista);
                      router.push('renomeardocumento');
                    break;
                case "alterarFase":
                  commit('setListaSelecionadosMesaTrabalho', payload.selectedMesaTrabalho);
                  router.push('alterarfase');
                  break;
                case "removerRelatorProcesso":
                  commit('setListaSelecionadosMesaTrabalho', novaLista);
                  router.push('removerrelatorprocesso');
                  break;
                case "distribuirProcessoProcuradoria":
                  commit('setListaSelecionadosMesaTrabalho', novaLista);
                  router.push('distribuirprocessoprocuradoria');
                  break;
                case "distribuirProcesso":
                  commit('setListaSelecionadosMesaTrabalho', novaLista);
                  router.push('distribuirprocessorelator');
                  break;
                case "adicionarPendencia":
                    commit('setListaSelecionadosMesaTrabalho', novaLista);
                    router.push('adicionarPendencia');
                    break;
          }
        }
    }
})

export default store;