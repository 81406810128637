<template>
	<Toast />
	<div>
		<router-view />
	</div>
	<ConfirmDialog></ConfirmDialog>
</template>

<script>
import EventBus from "@/event-bus";

export default {
	data() {
		return {
		}
	},
	mounted() {
		EventBus.$on("showError", (error) => {
      this.$toast.add({severity:'error', detail: error, life: 10000});
    });
		EventBus.$on("showSucess", (error) => {
      this.$toast.add({severity:'success', detail: error, life: 10000});
    });
	},
};

</script>

<style lang="scss">
	@import 'App.scss';
</style>
